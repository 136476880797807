import React, { useEffect, useState } from 'react'
import icon1 from '../../Assets/home/icons.png'
import axios from 'axios'

const WhatWereOfferingHome = () => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);  // Add loading state

    const getAllServices = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_KEY}/services/getAllServiceByBranchId/6745874a9aa6780fb268869a`,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                setServices(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getAllServices();
    }, []);

    const handleBookNow = (service) => {
        const phoneNumber = '919861688844'; // Replace with your phone number including country code
        const message = `Hello, I would like to book the service: ${service?.name}. Price: ₹ ${service?.price} for ${service?.duration} minutes.`;

        // URL encode the message to make sure it's formatted correctly
        const encodedMessage = encodeURIComponent(message);

        // Open WhatsApp with pre-filled message
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        // Open the WhatsApp chat
        window.open(whatsappUrl, '_blank');
    };
    return (
        <div>
            <div className="md:mx-16 mx-6">
                <div className="text-center py-12">
                    <p className="text-sm italic text-[#878353]">Our Services</p>
                    <h1 className="text-3xl md:text-5xl font-serif font-bold mt-2 text-[#202020]">What We’re Offering</h1>
                    <p className="text-gray-600 mt-4 max-w-5xl mx-auto">
                        Discover A World Of Tranquility, Luxury, And Personalized Care As You Embark On A Journey Of Self-Care And Renewal. Visit Us Today And Let Us Transport You To A Realm Of Beauty And Serenity.
                    </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                    {loading
                        ? Array(3).fill().map((_, index) => (
                            <SkeletonLoader key={index} />
                        ))
                        : services.map((service, index) => (
                            <ServiceCard
                                key={index}
                                image={service.image}
                                icon={icon1}
                                name={service.name}
                                description={service.description}
                                price={service.price}
                                duration={service.duration}
                                handleBookNow={handleBookNow}
                            />
                        ))}
                </div>
            </div>
        </div>
    )
}

export default WhatWereOfferingHome;

function ServiceCard({ image, icon, name, description, price, duration, handleBookNow }) {
    return (
        <div className="bg-white rounded-lg shadow-lg p-2 py-5 relative hover:bg-[#F0EEE1]">
            <div className="flex justify-center">
                <img src={image} alt={name} className="h-[18rem] w-[18rem] rounded-full  object-cover" />
            </div>
            <img src={icon} alt='img' className='w-[5rem] absolute left-[35%] top-[40%] md:left-[50%] md:top-[60%] lg:left-[40%] lg:top-[50%]' />
            <div className='mt-5'>
                <h2 className="text-xl font-bold mt-12 text-center font-serif">{name}</h2>
                <p className="text-gray-600 mt-2 text-center">{description}</p>
                {/* Show Price and Duration */}
                <div className="mt-4 text-center">
                    <p className="text-gray-700 text-lg font-semibold">{price} | {duration} minutes</p>
                </div>
                <div className="flex justify-center mt-4">
                    <button
                        onClick={() => handleBookNow({ name, price, duration })}
                        className="px-4 py-2 mt-auto bg-[#164805] hover:bg-[#3f6327] rounded-md w-32 duration-300 ease-in-out text-white">
                        Book Now
                    </button>
                </div>
            </div>
        </div>
    );
}

function SkeletonLoader() {
    return (
        <div className="bg-white rounded-lg shadow-lg p-2 py-5 relative">
            <div className="flex justify-center">
                <div className="w-[180px] h-[180px] bg-gray-300 rounded-full animate-pulse"></div>
            </div>
            <div className="mt-5">
                <div className="w-[120px] h-[20px] bg-gray-300 animate-pulse mx-auto"></div>
                <div className="w-[100%] h-[15px] bg-gray-300 animate-pulse mt-2 mx-auto"></div>
                <div className="w-[100%] h-[15px] bg-gray-300 animate-pulse mt-2 mx-auto"></div>
                <div className="w-[120px] h-[20px] bg-gray-300 animate-pulse mt-4 mx-auto"></div>
                <div className="flex justify-center mt-4">
                    <div className="w-[100px] h-[40px] bg-gray-300 animate-pulse"></div>
                </div>
            </div>
        </div>
    );
}
