import React, { useState } from 'react';
import axios from 'axios';
import img1 from '../../Assets/img/img1.png';
import img2 from '../../Assets/img/img2.png';
import img3 from '../../Assets/img/img3.png';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);  // Set loading state to true

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_KEY}/email-service/sendEmail`, {
                managerEmail: "bangkokspa2017@gmail.com",
                ...formData,
            });

            if (response.data.success) {
                setSuccess(true);
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    message: ''
                });
            } else {
                setError('Failed to send the message. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setError('Failed to send the message. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="bg-white p-8 md:p-16 flex flex-col md:flex-row">
                <div className="w-full md:w-1/2 pr-0 md:pr-8 mb-8 md:mb-0">
                    <p className="text-base text-[#878353] mb-2 italic">Get In Touch</p>
                    <h1 className="text-3xl md:text-4xl lg:text-5xl w-full lg:w-[80%] font-medium mb-4 text-[#202020] font-serif tracking-wide leading-tight md:leading-tight lg:leading-tight">We Will Be In Touch Shortly.</h1>
                    <p className="text-gray-600 mb-6">We'd Love To Hear From You! Whether You Have Questions About Our Treatments, Want To Book An Appointment, Or Need Assistance With Anything Else, Our Team Is Here To Help.</p>
                    <div className="mb-4">
                        <div className="flex items-center mb-4 md:mb-8">
                            <img src={img1} alt='img' className='h-16' />
                            <div className="ml-4">
                                <p className="font-bold text-[#878353]">HEAD OFFICE</p>
                                <p className="text-gray-600 w-full lg:w-[80%]">3rd Floor, Plot No 622 Janpath, Sahid Nagar, Bhubaneswar-751007</p>
                            </div>
                        </div>
                        <div className="flex mb-4 md:mb-8 items-center">
                            <img src={img2} alt='img' className='h-16' />
                            <div className="ml-4">
                                <p className="font-bold text-[#878353]">EMAIL SUPPORT</p>
                                <p className="text-gray-600">bangkokspa2017@gmail.Com</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <img src={img3} alt='img' className='h-16' />
                            <div className="ml-4">
                                <p className="font-bold text-[#878353]">LET'S TALK</p>
                                <p className="text-gray-600">9861688844</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full md:w-1/2 pl-0 md:pl-8">
                    <h2 className="text-3xl md:text-4xl lg:text-5xl font-medium  text-[#878353] font-serif mb-4">Send Us A Message.</h2>

                    <form onSubmit={handleSubmit} className='font-bold'>
                        <div className="mb-4">
                            <label className="block text-gray-700 mb-2" htmlFor="name">Name</label>
                            <input
                                className="w-full p-3 border border-gray-300 rounded-lg bg-gray-100 focus:outline-none"
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="flex flex-col md:flex-row mb-4">
                            <div className="w-full md:w-1/2 pr-0 md:pr-2 mb-4 md:mb-0">
                                <label className="block text-gray-700 mb-2" htmlFor="phone">Phone</label>
                                <input
                                    className="w-full p-3 border border-gray-300 rounded-lg bg-gray-100 focus:outline-none"
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full md:w-1/2 pl-0 md:pl-2">
                                <label className="block text-gray-700 mb-2" htmlFor="email">Email</label>
                                <input
                                    className="w-full p-3 border border-gray-300 rounded-lg bg-gray-100 focus:outline-none"
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 mb-2" htmlFor="message">Message</label>
                            <textarea
                                className="w-full p-3 border border-gray-300 rounded-lg bg-gray-100 focus:outline-none"
                                id="message"
                                name="message"
                                rows="4"
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                        </div>

                        <button
                            type="submit"
                            className="w-full bg-[#164805] text-white p-3 rounded-lg"
                            disabled={loading} // Disable button when loading
                        >
                            {loading ? 'Sending...' : 'Book Now'}
                        </button>
                    </form>

                    {/* Success or error messages */}
                    {success && <p className="text-green-500 mt-4">Message sent successfully!</p>}
                    {error && <p className="text-red-500 mt-4">{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
