import React from 'react'
import img from '../../Assets/about/aboutsimg.png'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const GetToknow = () => {
  const navigate = useNavigate();
  const pathName = useLocation().pathname;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 px-6 md:px-16 py-8 gap-8 ">
      <div data-aos="fade-right">
        <img className='w-full lg:h-full lg:w-full xl:w-[80%] mx-auto'
          src={img}
          alt="discoverGroupImg"
        />
      </div>
      <div className="flex flex-col gap-4 md:gap-8 my-auto " data-aos="fade-down">
        <div className="flex flex-col gap-4 justify-center">
          <h5 className="lato-thin-italic italic text-[#878353] mt-2 md:mt-4">Get To Know Us</h5>
          <h1 className="text-3xl md:text-5xl font-serif  font-semibold text-[#202020] md:leading-tight lg:leading-none xl:leading-tight tracking-wide">
            BangKok Spa Welcomes You For Achieving A Radiant And Confident Glow
          </h1>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-[#686767] font-bold poppins-thin leading-6 tracking-wide md:text-md  text-base capitalize w-[95%] ">
            Step into a sanctuary of relaxation and rejuvenation, right in the heart of the city. At Bangkok Spa, we combine traditional Thai techniques with modern wellness practices to provide a truly unique and restorative experience.
          </p>

        </div>
        <div className='text-center md:text-start'>
          { pathName !== "/about" && <button
            onClick={() => navigate("/about")}
            className="px-4 py-2 mt-auto bg-[#164805] hover:bg-[#3f6327] rounded-md w-32 duration-300 ease-in-out text-white">
            Read More
          </button>}
        </div>
      </div>
    </div>
  )
}

export default GetToknow

