import { React, useState, useEffect } from "react";
import Avatar1 from "../../Assets/Common/Avatar1.png";
import Avatar2 from "../../Assets/Common/Avatar2.png";
import Avatar3 from "../../Assets/Common/Avatar3.png";
import StarImage from "../../Assets/Common/StarImage.png";
import Line from "../../Assets/Common/Line.png";
import axios from 'axios';

function WhatTheySay() {
  const [reviews, setReviews] = useState([]);
  const fetchReviews = async () => {
    let config = {
      method: "get",
      maxBodyLenght: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/reviews/fetchReviews?PLACE_ID=ChIJzUQLVfoJGToRrUBKUDaA2TU&API_KEY=XYZ`,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response---->", response.data);
        setReviews(response.data);
      })
      .catch((error) => {
        console.log("Error in fetching reviews", error);
      });
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  const data = [
    {
      id: 1,
      avatar: Avatar1,
      avatarAlt: "Display picture of the person who gave the review",
      imageSrc: StarImage,
      imageAlt: "Rating with Stars",
      title: "Ananya Verma",
      subTitle: "Yoga Instructor",
      content: `"The spa experience was incredibly rejuvenating! The ambiance was tranquil, and the therapists were very attentive. It felt like a true oasis amidst the city's chaos."`,
    },
    {
      id: 2,
      avatar: Avatar2,
      avatarAlt: "Display picture of the person who gave the review",
      imageSrc: StarImage,
      imageAlt: "Rating with Stars",
      title: "Rajesh Kapoor",
      subTitle: "Business Analyst",
      content: `"From the moment I walked in, I felt relaxed. The staff was welcoming, and the massages are top-notch. I left feeling refreshed and ready to tackle my workweek."`,
    },
    {
      id: 3,
      avatar: Avatar3,
      avatarAlt: "Display picture of the person who gave the review",
      imageSrc: StarImage,
      imageAlt: "Rating with Stars",
      title: "Priya Menon",
      subTitle: "Teacher",
      content: ` "A wonderful experience! The spa treatments are personalized and professional, creating a perfect blend of relaxation and rejuvenation. Highly recommend to anyone in need of some pampering." `,
    },
  ];

  return (
    <div className="px-6 md:px-16 py-2 flex items-center justify-center flex-col">
      <p className="text-[#878353] italic">Testimonial</p>
      <h1 className="text-[#202020] font-serif text-center mt-2 md:mt-4 font-extrabold text-3xl md:text-5xl">
        What They Say
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 lg:gap-8">
        {reviews?.length === 0 ? data?.map((item) => (
          <div
            key={item.id}
            className="pt-8 items-center justify-center grid grid-cols-1 gap-16"
            data-aos={item.animation}
          >
            <div className="flex flex-col items-start p-6 gap-6 lg:p-8 rounded-lg bg-white hover:bg-[#F0EEE1] text-[#050F64] transition-all duration-300 ease-in-out group relative">
              <div className="flex">
                <img
                  src={item.avatar}
                  alt={item.avatarAlt}
                  className="h-16 md:h-20"
                />
                <div className="flex flex-col pl-4">
                  <img src={item.imageSrc} alt={item.imageAlt} />
                  <h1 className="font-serif font-bold md:text-lg lg:text-xl text-[#202020]">
                    {item.title}
                  </h1>
                  <p className="text-[#202020]">{item.subTitle}</p>
                </div>
              </div>
              <img src={Line} alt="this is a line" />
              {/* <p style={{ fontFamily: "Poppins, serif" }}>
                {item.content}
              </p> */}
              <p className="text-[#202020] tracking-wide leading-7 md:p-4 pt-2 capitalize opacity-80 font-semibold">
                {item.content}
              </p>
            </div>
          </div>
        )) : reviews?.map((item) => (
          <div
            key={item.id}
            className="pt-8 items-center justify-center grid grid-cols-1 gap-16"
            data-aos={item.animation}
          >
            <div className="flex flex-col items-start p-6 gap-6 lg:p-8 rounded-lg bg-white hover:bg-[#F0EEE1] text-[#050F64] transition-all duration-300 ease-in-out group relative">
              <div className="flex">
                <img
                  src={item.avatar}
                  alt={item.avatarAlt}
                  className="h-16 md:h-20"
                />
                <div className="flex flex-col pl-4">
                  <img src={item.imageSrc} alt={item.imageAlt} />
                  <h1 className="font-serif font-bold md:text-lg lg:text-xl text-[#202020]">
                    {item.title}
                  </h1>
                  <p className="text-[#202020]">{item.subTitle}</p>
                </div>
              </div>
              <img src={Line} alt="this is a line" />
              {/* <p style={{ fontFamily: "Poppins, serif" }}>
                {item.content}
              </p> */}
              <p className="text-[#202020] tracking-wide leading-7 md:p-4 pt-2 capitalize opacity-80 font-semibold">
                {item.content}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhatTheySay;
