import React from 'react'
import SimpleBanner from '../../Screens/Common/SimpleBanner'
import AboutUs_banner from '../../Assets/about/banarimg.png'
import GetToknow from '../../Components/abouts/GetToknow'
import SubscribeTo from '../../Components/home/SubscribeTo'
import OurPricing from '../../Components/Common/OurPricing'
import OurSpecialists from '../../Components/home/OurSpecialists'
import WhatTheySay from '../../Components/Common/WhatTheySay'
import ContactForm from '../../Components/Contact/ContactForm'
import WhatWereOfferingHome from '../../Components/home/WhatWereOfferingHome'
const About = () => {
  return (
    <div>
      <SimpleBanner
        image={AboutUs_banner}
        name="About"
        path="Home >> About"
      />
      <GetToknow/>
      <WhatWereOfferingHome />
      <OurPricing />
      <OurSpecialists />
      <WhatTheySay />
      <ContactForm />
      <SubscribeTo />

    </div>
  )
}

export default About
