import React from 'react'
import SimpleBanner from '../../Screens/Common/SimpleBanner'
import AboutUs_banner from '../../Assets/about/banarimg.png'
import OurSpecialists from '../../Components/home/OurSpecialists'
import SubscribeTo from '../../Components/home/SubscribeTo'
import ContactForm from '../../Components/Contact/ContactForm'
import WhatWereOfferingHome from '../../Components/home/WhatWereOfferingHome'
const Services = () => {
  return (
    <div>
      <SimpleBanner
        image={AboutUs_banner}
        name="Services"
        path="Home >> Services"
      />
      <WhatWereOfferingHome />
      <OurSpecialists />
      <ContactForm />
      <SubscribeTo />
    </div>
  )
}

export default Services