import React, { useEffect, useState } from "react";
import axios from 'axios';

const OurOffers = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true); // Start with loading set to true
  const [error, setError] = useState(null); // Error state

  const getAllOffers = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/offer/getAllOffersByBranchId/6745874a9aa6780fb268869a`,
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        console.log(response.data);
        setOffers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching offers:', error);
        setError('Failed to fetch offers. Please try again later.');
      })
      .finally(() => {
        setLoading(false);  // Set loading to false after the API call is completed
      });
  };

  useEffect(() => {
    getAllOffers();
  }, []);

  return (
    <div className="md:mx-16 mx-6 mt-10">
      <h1 className="text-3xl md:text-5xl font-serif font-bold mt-2 text-[#1E1E1E] text-center mb-2">
        Our Offers
      </h1>
      <p className="text-center text-gray-600 mb-8">
        We Seek To Build A Lasting Relationship With You Based On Excellence,
        Integrity, And Forward-Thinking. FG
      </p>

      {/* Skeleton Loader */}
      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="p-4 rounded-lg shadow-md animate-pulse">
              <div className="w-full h-60 bg-gray-300 rounded-lg mb-4"></div>
              <div className="w-3/4 h-6 bg-gray-300 rounded mb-2"></div>
              <div className="w-2/3 h-4 bg-gray-300 rounded"></div>
            </div>
          ))}
        </div>
      ) : error ? (
        <div className="text-center py-8">
          <p className="text-red-500">{error}</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {offers?.map((offer) => (
            <div
              key={offer?._id}
              className="p-4 rounded-lg shadow-md"
              data-aos={offer?.aosEffect}
            >
              <img
                src={offer?.image}
                alt={offer?.imageAlt}
                className="w-full rounded-lg mb-4"
              />
              <h2 className="text-xl font-bold mb-2">{offer?.name}</h2>
              <p className="text-gray-600">{offer?.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OurOffers;
